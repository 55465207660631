:root{
  /* Colores */
  --primary-color: #AA0000;
  --secondary-color: #830609;
  --wine-color: #610A0C;
  --yellow-color: #E4B744;
  --white-color: #EFEFEF;

  /* Tipografía */
  --font-size-base: 18px;
  --font-size-large: 24px;
  --font-size-small: 12px;

  /* Weight */
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* Espaciado */
  --padding-base: 1em;
  --margin-base: 1em;

  /* Otros */
  --border-radius: 5px;
}
/* *{
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: 'Montserrat';
  margin: 0 auto;
  background-color: #EFEFEF;
  box-sizing: border-box;
}

ul, li, a {
  text-decoration: none;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.btn{
  border-radius: 16px;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 12px 0px;
}

.hide{
  display: none !important;
}

.show{
  display: flex;
}





.a-c{
  align-items: center;
}

.j-c{
  justify-content: center;
}

.j-sb{
  justify-content: space-between;
}

.gap-10{
  gap: 10px;
}

.navbar-icon-log{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1.5px solid white;
  width: 88px;
  height: 88px;
  border-radius: 24px;
  text-align: center;
} */

/* Navbar */
/* .hamburger-menu-container {
  position: relative;
}

.hamburger-icon {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}

.hamburger-icon .bar1, .hamburger-icon .bar2, .hamburger-icon .bar3 {
  width: 35px;
  height: 4px;
  background-color: var(--white-color);
  margin: 8px 0;
  transition: 0.4s;
  border-radius: 8px;
}

.hamburger-icon.open .bar1 {
  transform: rotate(-45deg) translate(0px, 15px);
  width: 40px;
  border-radius: 24px;
}

.hamburger-icon.open .bar2 {
  opacity: 0;
}

.hamburger-icon.open .bar3 {
  transform: rotate(45deg) translate(0px, -16px);
  width: 40px;
  border-radius: 24px;
}

.menu {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: var(--secondary-color);
  height: 100vh;
  width: 60vw;
  margin-left: -20px;
  padding-left: 22px;
}

.menu.open {
  display: flex;
}

.menu a{
  padding: 20px 22px;
  text-decoration: none;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu a:active{
  background-color: #610A0C;
  margin-left: -20px;
}

.menu a:hover {
  background-color: #610A0C;
  margin-left: -20px;
  padding-left: 40px;
}

.navbar-icons svg{
  padding: 10px;
  width: 30px;
}

.navbar-mobile{
  background-color: var(--secondary-color);
  color: var(--white-color);
  max-width: 100%;
  padding: 0px 12px;
}

.navbar-mobile ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* Desktop 
@media (min-width: 1024px) {
  body{
    padding: 16px;
  }

  .navbar-mobile{
    display: none;
  }
  
  .navbar{
    padding-bottom: 16px;
    display: block !important;
    background-color: var(--secondary-color);
    color: var(--white-color);
    width: 88px;
    border-radius: 24px;
    padding:16px;
  }

  .navbar ul{
    height: 90vh;
  }

  .navbar-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88px;
  }

  .navbar-icon svg, .navbar-icon-log svg{
    width: 60%;
  }

  #menu-desk{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 16px;
    height: 10%;
  }
  
  .line{
    border-bottom: 2px solid var(--white-color);
  }

  .hamburger-icon .bar1, .hamburger-icon .bar2, .hamburger-icon .bar3 {
    margin: 6px 0;
    width: 60%;
  }

  .navbar-icon:hover{
    background-color: var(--wine-color);
    border-radius: 24px;
  }

  #menu-desk:hover{
    background-color: var(--wine-color);
    border-radius: 24px;
  }

  .navbar-icon:hover, .navbar-icon-log:hover{
    cursor: pointer;
  }

  .navbar li {
    display: none;
  }

  .navbar-wrapper{
    height: 88%;
  }

  .navbar-wrapper-1{
    margin-top: 16px;
  }

  .hamburger-icon.open .bar1 {
    transform: rotate(-45deg) translate(-12px, 10px);
    width: 50px;
  }
  
  .hamburger-icon.open .bar2 {
    opacity: 0;
  }
  
  .hamburger-icon.open .bar3 {
    transform: rotate(45deg) translate(-12px, -10px);
    width: 50px;
  
  }
}
*/


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
  font-size: var(--font-size-base);
}

html body{
  overflow: auto !important;

}
:root{
  --yellow-color: #E4B744;
  --brand-three: #610A0C;
}
body{
  background-color: #EFEFEF;
}
a{
  text-decoration: none;
  color: #232323;
}
button{
  border: none;
  background-color: transparent;
}
ul, li{
  list-style: none;
}
section{
  margin: 0px auto;
  max-width: 90%;
}
form{
  width: 100%;
}
h1{
  font-size: 32px;
  font-weight: 600;
}

.btn{
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 8px 0px;
  border-radius: 8px;
  font-size: 12px;
  max-width: 250px;
}
.btn-yellow{
  background-color: var(--yellow-color);
}
.btn-whatsapp{
  background-color: #218838;
}
.btn-edit-profile, .btn-my-orders, .btn-change-password{
  background-color: #ffffff;
  width: 100%;
  padding: 8px;
  text-align: start;
  margin-bottom: 12px;
}
.btn-inventory{
  max-width: 350px;
}
.br-8{
  border-radius: 8px;
}
.br-16{
  border-radius: 16px;
}
.f-h{
  display: flex;
}
.f-v{
  display: flex;
  flex-flow: column;
}
.bold{
  font-weight: var(--font-bold);
}
.mb-5{
  margin-bottom: 5px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-32{
  margin-bottom: 32px !important;
}
.mb-40{
  margin-bottom: 40px;
}
.mb-75{
  margin-bottom: 75px !important;
}
.mr-8{
  margin-right: 8px;
}
.mt-20{
  margin-top: 20px;
}
.mtb-40{
  margin: 40px 0px;
}
.mt-90{
  margin-top: 90px;
}
.font-big{
  font-size: 22px;
}
.txt-right{
  text-align: right !important;
}
.card-white{
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 2px 2px 11px 1px rgb(102 105 140 / 20%);

}
.txt-align-center{
  text-align: center;
}
.txt-align-rigth{
  text-align: end;
}
.mb-16{
  margin-bottom: 16px;
}
.mtb-16{
  margin: 16px 0px;
}
.mtb-20{
  margin: 20px 0px;
}
.mb-less-100{
  margin-bottom: -100px;
}
.jc-sb{
  justify-content: space-between;
}
.jc-c{
  justify-content: center;
}
.a-c{
  align-items: center;
}
.a-s{
  align-items: start;
}
.column{
  flex-flow: column;
}
.gap-5{
  gap: 5px;
}
.gap-16{
  gap: 16px;
}
.br-16{
  border-radius: 16px;
}
.overflow-hidden{
  overflow: hidden;
}
.h-90{
  height: 90vh;
}
.navbar, .logo-bar{
  height: 60px;
  width: 100vw;
}
.logo-bar img {
  width: 100%;
  height: 100%;
}
.logo-bar{
  background-color: var(--primary-color);
  position: relative;
  top:0px;
  text-align: center;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-menu{
  background-color: #F9F9F9;
  position: fixed;
  display: flex;
  bottom: 0px;
  z-index: 9;
  width: 100%;
  justify-content: space-around;
  padding-top: 10px;
  box-shadow: 2px 2px 11px 1px rgb(102 105 140 / 20%);
}
.navbar-item{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 52px;
  height: 52px;
}
.navbar-item path:active{
  fill: var(--primary-color);
}
.navbar-item a:active{
  color: var(--primary-color);
}

/* lOADER */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
.ticket-logo{
  width: 120px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}

/* Navbar */
@media (min-width: 1024px) {
  .logo-bar{
    width: 100vw;
  }
  .menu-icon {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-bottom: 2px solid white;
  }
  .navbar-item{
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 150px;
  }
  .navbar-item svg{
    width: 30px;
    height: 30px;
  }
  .navbar-menu{
    max-height: 60px;
    padding: 10px 0px;
    position: static;
    align-items: center;
    margin-bottom: 40px;
  }
  .menu-icon span {
      display: block;
      height: 2px;
      width: 70%;
      background-color: #fff;
      border-radius: 18px;
      margin-bottom: 7px;
  } 
  /* Hover y estado activo */
  .navbar-item:hover, .navbar-item.active {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
  }

  .navbar-item.active img {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
  }
}

.hero{
  margin-top: 12px;
}
.panel2 img{

}
.slider{
  max-width: 100vw;
}
.slider-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.slider-2 {
  display: none;
}
.slide-one{
  background-image: url('https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/State=Default,%20Page=Default.webp');
  background-size: cover;
  height: 250px;
  border-radius: 16px;
  padding: 30px;
  color: white;
  display: flex !important;
  flex-direction: column;
  text-align: left !important;
  align-content: space-around;
  gap: 20px;
  width: 100%;
}
.slide-one h1 p {
  width: 100%;
}
.slide-one .two{
  background-image: url('https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/State=Default,%20Page=2.webp');

}
.slide-one .three{
  background-image: url('https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/State=Default,%20Page=3.webp');

}
.flicking2 {
  width: 100%;
}

.panel2 {
  max-width: 100vw;
}

.panel2 img {
  width: 100%;
  max-height: 320px;
  object-fit: cover; /* Asegura que la imagen cubra todo el panel */
  border-radius: 8px; /* Esto es opcional para darle esquinas redondeadas */
}
/* .slide-bg{
  position: absolute;
  top: 0px;
  background-color: #557c0d;
  opacity: 50%;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.slide-content{
  position: absolute;
  top: 0px;
  padding: 12px 0px 0px 12px;
}
.slide-img{
  height: 100%;
  border-radius: 24px;
}
.slide-img img{
  width: 100%;
}
.owl-dots {
  bottom: 20px;
  right: 45%;
  position: absolute;
} */


@media (min-width: 1024px) {
  .container{
    max-width: 1300px;
    margin: auto;
  }
  .hero{
      display: flex;
      gap: 16px;
      max-height: 380px;
  }
  .slider{
      width: 70%;
      border-radius: 24px;
  }
  .slider-2 {
    display: block;
    max-width: 30%;
    height: auto;
    width: 100%;
    border-radius: 16px;
}
  .slider-ofertas{
      width: 30%;
      height: 380px;
      background-color: #557c0d;
      border-radius: 24px;
  }
  .owl-carousel,
  .owl-stage-outer,
  .owl-stage,
  .slide-img img{
      max-height: 380px;
  }
}

/* Products */
.product-image{
  display: flex;
  justify-content: center;
}
.products-wrapper{
  display: flex;
  flex-flow: wrap;
  overflow: hidden;
  justify-content: space-between;
}
.product-card{
  position: relative;
  background-color: #ffffff;
  width: 48%;
  text-align: center;
  padding: 14px;
  border-radius: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  box-shadow: 2px 2px 11px 1px rgb(102 105 140 / 20%);
}

.product-card h2{
  font-size: 16px;
  margin: 24px 0px 12px;
  min-height: 40px;

}
.product-card img{
  height: 150px;
  /* max-width: 100%; */
}
.product-image{
  overflow: hidden;
  border-radius: 16px;
}
.product-card a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
  border-radius: 8px;
}
.product-price{
  font-size: 32px;
  color: var(--secondary-color);
  font-weight: bold;
  padding: 12px 0px;
}
.product-card-dispo{
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0px 90px / 120px;
  padding: 5px 20px;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
}
.available {
  background-color: #6AC95A;

}
.limited {
  background-color: #E4B744;
}
.sold-out{
  background-color: #610A0C;
}
.sold-out-card{
  filter: grayscale(50%);
}
/* Counter */
/* .btns-counter{
  margin: 0 auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 150px;
} */
 .product-counter{
  display: flex;
  flex-flow: nowrap;
  align-items: center;
 }
.product-counter button{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: none;
  background-color: var(--yellow-color);
  color: var(--white-color);
  font-size: 20px;
}
.product-counter span{
  margin:0px 16px;
}
.quantity{
  font-size: 20px;
  font-weight: var(--font-medium);
}
.product-list h1{
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  padding: 0px 0px 24px;

}

/* Profile Info */
.profile-wrapper{
  background-color: #ffffff;
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  align-items: center;
  padding: 8px;
  flex-flow: wrap;
}
.profile-rol{
  background-color: var(--primary-color);
  color: #F9F9F9;
  border-radius: 8px;
  padding: 2px 27px;
}
/*IPAD*/
@media (min-width: 600px) {
  section{
    margin: 0px auto;
    max-width: 90%;

  }
  .products-wrapper{
    gap: 12px;
    justify-content: start;
  }
  .product-card{
    width: 48%;
    height: auto;
  }

}
/*Desktop*/
@media (min-width: 1024px) {
  .product-image{
    overflow: hidden;
  }
  .product-card{
    padding: 22px 15px;
  }

  .product-card img{
    max-height: 180px;
    width: auto;
  }
  .product-card h2{
    height: auto;
    margin-bottom: 15px;
  }
  .product-card{
    width: 19%;
    margin-bottom: 0px;
    height: auto;
  }
  .products-wrapper{
    gap: 12px;
  }
  .product-card h2{
    min-height: 44px;
    margin: 12px 0px 0px;
  }
  .product-price{
    padding: 5px 0px;
  }
  .btn-counter{
    width: 31px;
    height: 31px;
    font-size: 25px;
  }
  .quantity{
    font-size: 36px;
  }
}

/* Slider Social Media*/
.slider-title{
  margin: 42px 0px 16px;
  text-align: center;
}
.flicking{
  margin-bottom: 24px;
  width: 100vw;
  margin-left: -16px;
}
.flicking .panel {
  width: 48%;
  height: 380px;
  overflow: hidden;
  border-radius: 16px;
  transition: transform 0.3s ease-in-out;  /* Suaviza la transición */
  transform: scale(0.8);  /* Reduce el tamaño de los slides no activos */

}
.flicking .panel video{
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: auto;
  border-radius: 16px;

}
.flicking .panel.active {
  transform: scale(1);  /* Aumenta el tamaño del slide activo */
}

/* Cart */
.cart{
  margin-bottom: 100px;
}
.cart-card, .cart-total{
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
}
.cart-card{
  gap: 16px;
}
.cart-card-img{
  overflow: hidden;
}
.cart-card-img img{
  border-radius: 16px;
  width: 90%;
}
.cart-card-50{
  width: 50%;
}
.note{
  margin: 0px 0px 18px;
  text-align: start;
  font-size: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  font-weight: bold;
  padding: 8px;

}
.trash{
  width: 40px;
  height: 40px;
  color: #218838;
  fill: #232323;
}
.cart-card-50:nth-child(2){
  width: 50%;
  flex-direction: column;
}
.cart-total{
  flex-direction: column;
  gap: 24px;
}
.cart-total div{
  display: flex;
  justify-content: space-between;
}
.btn-wrapper{
  margin: 0px 16px;
}
.btn-pedido{
  background-color: var(--primary-color);
  width: 100%;
}
/* .btn-admin-access{
  margin-bottom: 15px;
  background-color: var(--brand-three);
} */
.bold{
  font-weight: var(--font-bold);
}
.color-grey{
  color: #808080;
}
.color-black{
  color: #232323;
}
.final-cost{
  text-align: end;
  margin-top: 28px;
}
.btns-counter{
  margin-top: 30px;
}
#cart-item{
  position: relative;
}
.cart-count{
  position: absolute;
  top: -10px;
  right: 0px;
  background-color: var(--yellow-color);
  color: var(--brand-three);
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-top{
  border-top: var(--secondary-color) 2px solid;
  margin: 0px 16px;
}
.shipping-method{
  margin-bottom: 90px;
}

/* NOTE MODAL */
.modal-overlay{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}
.modal-content textarea{
  padding: 10px;
  border-radius: 8px;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.modal-body textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-footer button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-footer .btn-save {
  background-color: #28a745;
  color: #fff;
}

.modal-footer .btn-cancel {
  background-color: #dc3545;
  color: #fff;
}

.modal-footer .btn-save:hover {
  background-color: #218838;
}

.modal-footer .btn-cancel:hover {
  background-color: #c82333;
}

/* SHIPPING METHOD CARDS*/
.shipping-option {
  background-color: #F9F9F9;
  border-radius: 8px;
  margin: 0px auto;
  padding: 16px;
}
.shipping-option{
  margin-bottom: 16px;
}
.first-wrapper{
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.store-pickup, .foreign-shipping, .home-delivery{
  margin-bottom: 16px;
  background-color: #F9F9F9;
}
.method_price{
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.method_price svg{
  margin-left: 8px;
}
.shipping-icon{
  margin-top: 16px;
  width: 100%;
}
.shipping-icon svg{
  margin-right: 12px;

}
.store-pickup, .foreign-shipping, .home-delivery{
  border-radius: 0px 0px 8px 8px;
}
.store-pickup div:first-child, .foreign-shipping,.home-delivery{
  display: flex;
  flex-flow: column;
}
.selected{
  border: #AA0000 2px solid;
}
.confirm-button{
  width: 100%;
  background-color: var(--primary-color);
  padding: 8px 0px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}
.confirm-button:disabled {
  background-color: #cccccc; /* Gris */
  color: #666666; /* Gris oscuro */
  cursor: not-allowed; /* Indicador de no clic */
}
.arrow {
  transition: transform 0.2s ease-in-out;
}
.shipping-option.selected .arrow {
  transform: rotate(90deg);
}
.foreign-shipping label, .store-pickup label, .home-delivery label, .pickup-point label, .package-shipping label{
  display: block;
}
.foreign-shipping input, .store-pickup input, .home-delivery input , .pickup-point input, .package-shipping input{
  display: block;
  width: 100%;
  border: none;
  background-color: #EFEFEF;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.national-shipping input{
  width: 100%;
  border: none;
  background-color: #EFEFEF;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
/* Doprdown Shipping*/
.ant-dropdown-trigger{
  display: block;
  width: 100%;
  background-color: #EFEFEF;
  padding: 12px 16px;
  margin-bottom: 16px;
  color: #232323 !important;
  font-size: 16px;
  font-weight: 400;
}
.ant-dropdown-trigger div:first-child{
  display: flex;
  justify-content: space-between;
}

/*Order Summary*/
.total-card{
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-flow: wrap;
  margin: 16px 0px;
}
.total-card div{
  width: 50%;
}
.item-image-wrapper{
  max-width: 160px;
  margin-right: 16px;

}
.item-image{
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* LOG IN */
.login-input, .register-form{
  width: 100%;
  border-radius: 4px;
  padding: 8px 8px;
  margin-bottom: 16px;
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  
  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4CAF50;
      
      &::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
      }
      
      &::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
      }
      
      &::before, &::after {
          content: '';
          height: 100px;
          position: absolute;
          background: #FFFFFF;
          transform: rotate(-45deg);
      }
      
      .icon-line {
          height: 5px;
          background-color: #4CAF50;
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
          
          &.line-tip {
              top: 46px;
              left: 14px;
              width: 25px;
              transform: rotate(45deg);
              animation: icon-line-tip 0.75s;
          }
          
          &.line-long {
              top: 38px;
              right: 8px;
              width: 47px;
              transform: rotate(-45deg);
              animation: icon-line-long 0.75s;
          }
      }
      
      .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid rgba(76, 175, 80, .5);
      }
      
      .icon-fix {
          top: 8px;
          width: 5px;
          left: 26px;
          z-index: 1;
          height: 85px;
          position: absolute;
          transform: rotate(-45deg);
          background-color: #FFFFFF;
      }
  }
}

@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

/* Edit Products*/
.edit-products-page ul{
  display: flex;
  flex-flow: wrap;
  gap: 12px;
  justify-content: space-between;
}
.edit-products-page li{
  display: flex;
  flex-direction: column;
  height: 100%; /* Ajusta según el tamaño que necesites */
  justify-content: space-between;
  gap: 12px;
}
.edit-products-card{
  width: 47%;
}
.edit-products-image{
  max-height: 150px;
}
.ant-input-number-outlined {
  width: 98%;
}

/*PDF Order*/
.ticket-logo{
background-color: var(--primary-color);
text-align: center;
width: 100%;
}
.ticket-logo img{
  width: 50px;
}
.border{
  border-bottom: solid #AA0000 1px;
  font-size: 26px;
}
.politicas h1, .politicas h2{
  color: var(--primary-color);
}
.politicas h1{
  text-align: center;
  border: 3px solid var(--primary-color);
  border-radius: 8px;
  margin-bottom: 15px;
}
.politicas  h2{
  margin: 15px 0px;
}
.password-container{
  display: flex;
}
.show-password-btn{
  background-color:#830609;
  border-radius: 8px;
  margin-bottom: 16px;
  color: white;
  padding: 5px 10px;
  margin-left: 5px;
  font-size: 14px !important;
}
.order-items{
  gap: 16px;
  margin: 20px 0px;
  flex-flow: wrap;
  justify-content: left;
}
/* .order-item{
  border: 1px solid gainsboro;
} */
 .order-wrapper{
  border: 2px solid var(--primary-color);
  margin: 20px 0px;
  border-radius: 8px;
  padding: 10px;
 }

 .item-cards-container {
  margin-top: 15px;

}

.item-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 15px;

}

.item-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.item-card p {
  margin: 5px 0;
  font-size: 16px;
}
/* Estilo para los días habilitados */
.ant-picker-cell-in-view {
  color: black !important; /* Color negro para los días habilitados */
}

/* Estilo para los días deshabilitados */
.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: grey !important; /* Color gris para los días deshabilitados */
  background-color: transparent !important; /* Sin fondo para días deshabilitados */
}
:where(.css-dev-only-do-not-override-11lehqq).ant-picker-dropdown .ant-picker-cell{
  color: black !important;
}

.tooltip-icon {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 50%;
  padding: 0 5px;
  font-size: 12px;
}

.tooltip-icon:hover {
  background-color: #007bff;
  color: white;
}

/* Alternativa para tooltip más detallado */
.tooltip-icon:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  top: 100%;
  right: 0;
  max-width: 100px;  
}
.position-relative{
  position: relative;
}
.method_price{
  font-weight: bold;
}
.font-size-xs{
  font-size: 12px;
}
.font-size-m{
  font-size: 16px !important;
}
.input-counter{
  border: var(--primary-color) 1px solid;
  padding: 15px 20px;
  text-align: center;
  border-radius: 8px;
  margin: 0px 10px;
  font-size: 16px;
}

.edit-products-card{
  max-width: 250px;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-row{
  display: block !important;
}
.label-product-name{
  width: 100%;
}
.ant-form-item .ant-form-item-label{    overflow: visible !important;
}
.w-50{
  width: 50%;
}
#name{
  width: 100%;
}
.ant-col-14{
  max-width: 100% !important;
}
.timer {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 12px;
}
@media (min-width: 1024px) {
  .edit-products-page ul{
    justify-content: center;
  }


}
.logo-wrapper-login{
  background-color: var(--primary-color);
  height: 40vh;
}
.logo-wrapper-login img{
  width: 200px;
}
.logo-wrapper-login h1{
  color: white;
  font-weight: bold;
  font-size: 32px;
}
.login-section{
  margin-top: -20px;
  position: relative;
  z-index: 2;
  background-color: white;
  padding: 25px;
  border-radius: 16px;
  height: 65vh;
}
.no-overflow{
  overflow: hidden;
  max-height: 100vh;
}
.register-form input, .register-form select, .login-input{
  border: 1px solid rgba(177, 177, 177, 0.543);
  font-size: 14px;
}

.login-form input{
  border-radius: 8px;
}
.login-form p{
  margin-bottom: 8px;
}
.login-form button, .btn-register{
  font-size: 18px;
}
.go-back-button{
  width: 32px;
  height: 32px;
  margin-left: 25px;
  align-self: flex-start;
}
.order-item{
  border-bottom: 1.5px solid var(--primary-color);
  margin-bottom: 15px;
}
.item-wrapper{
  margin: 10px 0px;
}
.filter-orders{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.filter-orders select{
  padding: 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 11px 1px rgb(102 105 140 / 20%);
  border: none;

}
.maintenance-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 180px);
  padding-top: 50px;
}
.maintenance-wrapper img{
  max-width: 80%;
}
.maintenance-message-edit textarea{
  width: 100%;
  min-height: 50px;
  border-radius: 8px;
  padding: 10px;
  border: var(--primary-color) 1.5px solid;
  margin: 15px 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-1:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-1 {
  background-color: #4caf50; /* Color cuando está activado */
}

input:checked + .slider-1:before {
  transform: translateX(26px); /* Desplaza el círculo del switch hacia la derecha */
}
/* .admin-wrapper{
  display: flex;
  gap: 8px;
  justify-content: space-between;
} */
.admin-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  background-color: var(--secondary-color);
  border-radius: 16px;
  height: 180px;
  color: white;
  padding: 0px 10px;
}
.admin-item-1 {
  width: 100% !important;
}
.admin-item svg{
  width: 32px;
}
.font-xs{
  font-size: 12px;
}
.admin-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

/* Estilos para los items */
.admin-item-1 { 
  grid-area: 1 / 1 / 2 / 2; 
}
.admin-item-2 { 
  grid-area: 1 / 2 / 2 / 3; 
  background-color: var(--brand-three);
}
.admin-item-3 { 
  grid-area: 2 / 1 / 3 / 3; /* Ocupar toda la fila inferior */
  background-color: var(--primary-color);
}
.admin-item-4 { 
  grid-area: 3 / 1 / 3 / 3; /* Ocupar toda la fila inferior */
  background-color: var(--brand-three);
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 768px) {
  .admin-wrapper {
    grid-template-columns: 1fr; /* Una columna */
    grid-template-rows: repeat(auto, 1fr); /* Tres filas */
  }

  .admin-item-1 { 
    grid-area: 1 / 1 / 2 / 2;
  }
  .admin-item-2 { 
    grid-area: 1 / 2 / 2 / 3;
  }
  .admin-item-3 { 
    grid-area: 2 / 1 / 3 / 3;
  }
}
.loading-wrapper{
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-order {
  display: block;
  width: 100%;
  border: none;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 2px 2px 11px 1px rgb(102 105 140 / 20%);

  margin-bottom: 16px;
}
.search-order-label{
  text-align: center;
  margin: 35px 0px 15px 0px;
  font-size: 18px;
  font-weight: 500;
}
.ticket-body h1{
 text-align: center;
}

.font-xl{
  font-size: 20px;
  word-break: break-all;
}
.filter-products {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 20px 0px;
}
.ante-select{
  width: 250px !important;
}

@media (min-width: 1024px) {
  .filter-products {
    justify-content: end;
  }
}
.completed-row {
  background-color: #d4edda; /* Fondo verde claro */
}
.completed-card {
  background-color: #d4edda; /* Color verde claro */
  border-left: 5px solid #28a745; /* Borde verde oscuro */
}

.completed-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 50px;
  position: relative;
  top: -20px;
}
.wrap{
  flex-wrap: wrap-reverse;
  gap: 16px;
}
.center-mobile{
  justify-content: center;
}
@media (min-width: 1024px) {
  .completed-icon {
    width: 150px;
    top: 0px;
  }
.center-mobile{
  justify-content: space-between;
}
}
.ant-table {
  width: 100%;
  overflow-x: auto;
}

.ant-table-cell {
  word-wrap: break-word;
  white-space: normal;
}
@media (max-width: 768px) {
  .ant-table th, 
  .ant-table td {
    font-size: 12px;
    padding: 8px;
  }
  
  .ant-table {
    font-size: 12px;
  }
}
.wrap-noreverse{
  flex-flow: wrap;
}

.btn-print svg, .btn-download svg{
  width: 32px;
}
.btn-print, .btn-download{
  min-width: 100px;
  min-height: 50px;
}
.printed-row{
  background-color: #c823333b;
  position: relative;
}
.printed-label{
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bolder;
  font-size: 32px;
  color: var(--brand-three);
}